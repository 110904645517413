import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ReactGA from "react-ga4";
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from "react-router";

const Layout = ({ children }) => {
  const locotion = useLocation();
  const path = locotion.pathname;

  ReactGA.send({ hitType: "pageview", page: path });
  ReactPixel.pageView();

  return (
    <>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
